import {Client} from '../../../shared/utilities/type-helpers';
import {environment as env} from './environment.live';
const client: Client = {clientType: 'claro'};

export const environment = {
  ...env,
  // For Firebase JS SDK v7.20.0 and later, measurementId is optional
  firebaseConfig: {
    apiKey: 'AIzaSyDjSoEzEaJi_eJmDI0Pi5QNDfDg0F039gY',
    authDomain: 'sears-staging.firebaseapp.com',
    databaseURL: 'https://sears-staging-default-rtdb.firebaseio.com',
    projectId: 'sears-staging',
    storageBucket: 'sears-staging.appspot.com',
    messagingSenderId: '615366377746',
    appId: '1:615366377746:web:2d51fdd2bf99267a4d73cb',
    measurementId: 'G-D6QKC44K0E',
  },
  client,
};
